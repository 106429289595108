import { format, isFuture } from 'date-fns'
import { getProjectStatusString } from './string-utils'

export function cn(...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(publishedAt)
}

export function getBlogUrl(publishedAt, slug) {
  return `/blog/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function buildImageObj(source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function isBrowser() {
  return typeof window !== 'undefined'
}

export function isRetina() {
  return window.devicePixelRatio > 1
}

export function isTopOut(el, offset) {
  if (el) {
    return el.getBoundingClientRect().top < 0 + offset
  }
}

export function getNextNodeSlug(slug, nodes) {
  const slugs = nodes.map(node => {
    return node.slug.current
  })
  const currentSlugStr = slug.current
  const currentIndex = slugs.indexOf(currentSlugStr)
  return nodes[currentIndex + 1] ? nodes[currentIndex + 1].slug.current : nodes[0].slug.current
}

export function getFilteredProjects(projects, selectedStatus, selectedCategory, selectedArea) {
  return projects.filter(project => {
    const categoryTitles = project._rawCategories.map(category => category.title)
    const status = project._rawStatus.title
    const area = project._rawArea.title
    if (!selectedArea && !selectedStatus && !selectedCategory) {
      return project
    } else if (categoryTitles.includes(selectedCategory)) {
      if (!selectedArea && !selectedStatus) {
        return project
      } else {
        if (area === selectedArea) {
          if (!selectedStatus) {
            return project
          } else {
            if (status === selectedStatus) return project
          }
          return project
        } else if (status === selectedStatus) {
          if (!selectedArea) {
            return project
          } else if (area === selectedArea) return project
        }
      }
    } else if (status === selectedStatus) {
      if (!selectedCategory && !selectedArea) {
        return project
      } else {
        if (categoryTitles.includes(selectedCategory)) {
          if (!selectedArea) {
            return project
          } else if (area === selectedArea) return project
        } else if (area === selectedArea) {
          if (!selectedCategory) {
            return project
          } else if (categoryTitles.includes(selectedCategory)) return project
        }
      }
    } else if (area === selectedArea) {
      if (!selectedCategory && !selectedStatus) {
        return project
      } else {
        if (categoryTitles.includes(selectedCategory)) {
          if (!selectedStatus) {
            return project
          } else if (status === selectedStatus) return project
        } else if (status === selectedStatus) {
          if (!selectedCategory) {
            return project
          } else if (categoryTitles.includes(selectedCategory)) return project
        }
      }
    }
  })
}
