export function ucfirst(str) {
  return `${str.substr(0, 1).toUpperCase()}${str.substr(1)}`
}

export const getPreviewAreaString = (areaPreviewType, lotSize, grossFloorArea, useabeArea) => {
  if (areaPreviewType === 'lotSize') return `Grundstücksgröße ${lotSize}m2`
  else if (areaPreviewType === 'gorssFloorArea') return `Bruttogeschossfläche ${grossFloorArea}m2`
  else if (areaPreviewType === 'useableArea') return `Nutzfläche ${useabeArea}m2`
}

export const getProjectStatusString = projectStatus => {
  if (projectStatus === 'in-planung') return 'In Plannung'
  else if (projectStatus === 'completed') return 'Realisiert'
  else if (projectStatus === 'in-stock') return 'Im Bestand'
}

export const lsepFix = string => {
  return string.replace(/\u2028/g, '')
}
