import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { imageUrlFor } from '../lib/image-url'
import { buildImageObj } from '../lib/helpers'

function SEO({ description, lang, meta, ogImage, keywords, title }) {
  if (process.env.GATSBY_IS_PREVIEW_SERVER === '1') {
    return
  } else
    return (
      <StaticQuery
        query={detailsQuery}
        render={data => {
          const metaDescription = description || (data.site && data.site.description) || ''
          const siteTitle = (data.site && data.site.title) || ''
          const ogImage =
            data.site && data.site.ogImage
              ? imageUrlFor(buildImageObj(data.site.ogImage))
                  .width(1200)
                  .url()
              : ''

          // const siteAuthor = (data.site && data.site.author && data.site.author.name) || ''
          return (
            <Helmet
              htmlAttributes={{ lang }}
              title={title}
              titleTemplate={title === siteTitle ? '%s' : `%s | ${siteTitle}`}
              meta={[
                {
                  name: 'description',
                  content: metaDescription,
                },
                {
                  property: 'og:title',
                  content: title,
                },
                {
                  property: 'og:description',
                  content: metaDescription,
                },
                { property: 'og:image', content: ogImage },
                {
                  property: 'og:type',
                  content: 'website',
                },
                {
                  name: 'twitter:card',
                  content: 'summary',
                },
                // {
                //   name: 'twitter:creator',
                //   content: siteAuthor
                // },
                {
                  name: 'twitter:title',
                  content: title,
                },
                {
                  name: 'twitter:description',
                  content: metaDescription,
                },
                {
                  name: 'robots',
                  content: 'index, follow',
                },
              ]
                .concat(
                  keywords && keywords.length > 0
                    ? {
                        name: 'keywords',
                        content: keywords.join(', '),
                      }
                    : []
                )
                .concat(meta)}
            />
          )
        }}
      />
    )
}

SEO.defaultProps = {
  lang: 'de',
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  ogImage: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      title
      description
      keywords
      ogImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          url
        }
      }
    }
  }
`
